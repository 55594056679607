import React from 'react'

function SectionHeader(props) {
  return (
    <section className="section-header u-text-center">
      <div className="container">
        <div className="section-header__inner">
          <h1 className="section-header__heading text--3">{props.children}</h1>
          {props.paragraph && <p className="section-header__paragraph text--4">
            {props.paragraph}
          </p>}
        </div>
      </div>
    </section>
  )
}

export default SectionHeader
