import React from 'react'

import SEO from '../components/seo'

import Layout from '../components/layout'
import SectionHeader from '../components/SectionHeader/SectionHeader'

import VirtualTourItem from '../components/VirtualTourItem/VirtualTourItem'
import BottomNav from '../components/BottomNav/BottomNav'

const OurWorkPage = () => (
  <Layout>
    <SEO
      title="360 Virtual Tour"
      keywords={[
        `Emersoft`,
        `application`,
        `react`,
        `London`,
        `digital agency`,
      ]}
    />
    <SectionHeader>360 Virtual Tour</SectionHeader>
    <VirtualTourItem
      additionalClass="featured-websites__single-work--no-border"
      title="Condo, Las Vegas"
      description="Real-Estate Tour"
      src="https://kuula.co/share/collection/7lhPv?fs=1&vr=1&iosfs=1&thumbs=1&chromeless=1&logo=-1"
      isLong
    />
    <VirtualTourItem
      title="Artsy Nannies Town Square, Las Vegas"
      description="Beginning Tour "
      src="https://kuula.co/share/collection/7lhXV?fs=1&vr=1&iosfs=1&thumbs=1&chromeless=1&logo=-1"
      isLong
    />
    <VirtualTourItem
      title="Bella Smiles, Las Vegas"
      description="Cosmetic Dentistry Office Tour"
      src="https://kuula.co/share/collection/7lhdk?fs=1&vr=1&iosfs=1&thumbs=1&chromeless=1&logo=-1&logosize=40"
      isLong
    />
    <VirtualTourItem
      title="Dream Exotic Cars Rental, Las Vegas"
      description="Car Rentals Tour"
      src="https://kuula.co/share/collection/7lhVR?fs=1&vr=1&thumbs=1&chromeless=1&logo=-1"
      isLong
    />
    <BottomNav
      firstLink="/services"
      firstHeading="Services"
      secondLink="/about"
      secondHeading="Who we are"
    />
  </Layout>
)

export default OurWorkPage
