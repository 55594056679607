import React from 'react'

function VirtualTourItem(props) {
  return (
    <article
      className={
        'featured-websites__single-work virtual-item__single ' +
        (props.additionalClass ? props.additionalClass : '')
      }
    >
      <div className="container">
        <div className="virtual-tour-item__wrapper">
          <iframe
            src={props.src}
            title={props.title}
            className="virtual-tour-item__iframe"
            width="1180"
            height="800"
            frameBorder="0"
            allow="vr,gyroscope,accelerometer,fullscreen"
            scrolling="no"
            allowFullScreen="true"
          />
        </div>

        <div className="featured-websites__content">
          <h3
            className={
              `featured-websites__single-heading text--3 underline ` +
              (props.isLong ? `virtual-tour-item__heading--long` : ``)
            }
          >
            {props.title}
          </h3>
          <p
            className="featured-websites__single-work-paragraph
                    text--5
                    center"
          >
            {props.description}
          </p>
        </div>
      </div>
    </article>
  )
}

export default VirtualTourItem
